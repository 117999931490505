import { ApolloClient } from '@apollo/client'
import type { ApolloContext } from '~/services/types/Gql'
import smsTemplateCreate from '@/graphql/templates/smsTemplateCreate.gql'
import smsTemplateUpdate from '@/graphql/templates/smsTemplateUpdate.gql'
import smsTemplates from '@/graphql/templates/getSmsTemplates.gql'
import smsTemplateSubmitWhatsappApproval from '@/graphql/templates/smsTemplateSubmitWppApproval.gql'
import smsTemplateDelete from '@/graphql/templates/smsTemplateDelete.gql'
import { SmsTemplateCreate, SmsTemplateListType, SmsTemplateSubmitWhatsappApproval, SmsTemplateDelete, SmsTemplateUpdate } from '@/types/generated'

type RouteIds = {
  phoneNumberId: string,
  tenantId: string
}
type PageParams = {
  page: number,
  itemsPerPage: number
}
interface ITemplateGql {
  $apollo: ApolloClient<any>
  create: (
    name: string,
    phoneNumberId: string | null,
    template: string,
    templateType: string,
    tenantId: string,
    shared: boolean
  ) => Promise<SmsTemplateCreate>
  update: (
    name: string,
    phoneNumberId: string | null,
    template: string,
    templateType: string,
    tenantId: string,
    templateId: string
  ) => Promise<SmsTemplateUpdate>
  getAll: (
    routeIds: RouteIds,
    pageParams: PageParams,
    searchParams: {status: string, templateType: string, searchText: string}
  ) => Promise<SmsTemplateListType>
  verifyWppTemplate: (
    id: string,
    tenantId: string
  ) => Promise<SmsTemplateSubmitWhatsappApproval>
  delete: (
    id: string,
    tenantId: string
  ) => Promise<SmsTemplateDelete>
}
export default class TemplateGql implements ITemplateGql {
  $apollo: ApolloClient<any>

  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  create (
    name: string,
    phoneNumberId: string | null,
    template: string,
    templateType: string,
    tenantId: string,
    shared = false
  ): Promise<SmsTemplateCreate> {
    return this.$apollo.mutate({
      mutation: smsTemplateCreate,
      variables: {
        name,
        phoneNumberId,
        template,
        service: templateType,
        tenantId,
        shared
      }
    }).then(result => result.data.smsTemplateCreate)
  }

  update (
    name: string,
    phoneNumberId: string | null,
    template: string,
    templateType: string,
    tenantId: string,
    templateId: string
  ): Promise<SmsTemplateUpdate> {
    return this.$apollo.mutate({
      mutation: smsTemplateUpdate,
      variables: {
        name,
        phoneNumberId,
        template,
        templateType,
        tenantId,
        templateId
      }
    }).then(result => result.data.smsTemplateUpdate)
  }

  getAll ({ tenantId, phoneNumberId }: RouteIds,
    { page, itemsPerPage }: PageParams,
    { status, templateType, searchText }: {status: string, templateType: string, searchText: string}): Promise<SmsTemplateListType> {
    return this.$apollo.query({
      query: smsTemplates,
      fetchPolicy: 'no-cache',
      variables: {
        phoneNumberId,
        status,
        templateType,
        tenantId,
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        searchText
      }
    }).then(result => result.data.smsTemplates)
  }

  verifyWppTemplate (id: string, tenantId: string): Promise<SmsTemplateSubmitWhatsappApproval> {
    return this.$apollo.mutate({
      mutation: smsTemplateSubmitWhatsappApproval,
      variables: {
        id,
        tenantId
      }
    }).then(result => result.data.smsTemplateType)
  }

  delete (id: string, tenantId: string): Promise<SmsTemplateDelete> {
    return this.$apollo.mutate({
      mutation: smsTemplateDelete,
      variables: {
        id,
        tenantId
      }
    }).then(result => result.data.smsTemplateDelete)
  }
}
